/**
 *
 *
 *
 *  @author Robert Zink
 */
var MapManager = (function()
{
    var self = this;

    // Private properties
    var markers = [];

    var domId;
    var zoom = 12;
    var centerPos = 0;
    var lngCenter = 0;
    var mapTypeId = 0;
    self.iconImage = null;
    var map;


    // Public properties
    this.publicVariable = 1;

    function MapManager (domId, latCenter, lngCenter, zoom, mapType, iconImage)
    {
        self.domId      = domId;
        //self.myCenter = new google.maps.LatLng(latCenter, lngCenter);
        self.centerPos  = {lat: latCenter, lng: lngCenter};
        self.zoom       = zoom;
        self.mapTypeId  = getTypeId(mapType);
        self.iconImage  = iconImage;


    }

    function getMapProperties() {
        return {
            center: self.centerPos,
            zoom: self.zoom,
            mapTypeId: self.mapTypeId
        };
    }

    function setCenterMarker() {
        var marker=new google.maps.Marker({
            position:   self.centerPos,
            map:        map,
            animation:  google.maps.Animation.DROP,
            icon:       self.iconImage
        });
    }

    // Private methods
    function getTypeId (mapType)
    {
        switch(mapType) {
            case 'ROADMAP':     return google.maps.MapTypeId.ROADMAP;
            case 'SATELLITE':   return google.maps.MapTypeId.SATELLITE;
            case 'HYBRID':      return google.maps.MapTypeId.HYBRID;
            case 'TERRAIN':     return google.maps.MapTypeId.TERRAIN;
            default:            return google.maps.MapTypeId.ROADMAP;
        }
    }

    function addMartkersToMap() {
        if(map) {
            for (var i = 0; i < markers.length; i++) {
                markers[i].setMap(map);
            }
        }
    }

    // Public methods
    MapManager.prototype.addMarker = function (lat,lng, message) {
        var marker = new google.maps.Marker({
                            position: {lat: lat, lng: lng},
                            //map: map,
                            title: 'Uluru (Ayers Rock)',
                            animation:  google.maps.Animation.DROP,
                            icon:       self.iconImage
                    });
        if(message) {
            var infowindow = new google.maps.InfoWindow({
                content: message
            });

            marker.addListener('click', function() {
                infowindow.open(map, marker);
            });
        }
        markers.push(marker);
    };



    MapManager.prototype.init = function() {
        var mapProp = getMapProperties();
        map =   new google.maps.Map(document.getElementById(self.domId), mapProp);
        setCenterMarker();
        addMartkersToMap();
    };

    return MapManager;
}());

function initialize() {
    google.maps.event.addListener(marker, 'click', function() {
        infowindow.open(map,marker);
    });

}


