/**
 * Created by AMA on 24.08.2017.
 * Filter DOM for twig event list
 */
var EventFilterManager = (function(){

  var self = this;
  self.dataObjList = null;        //Event Block DOM Cache
  self.objLocationList = null;    //Event Location DOM Cache
  self.objEventTypeList = null;   //Event Type DOM Cache

  self.searchEventTypeStr = '';   //Selected EventType Optiontext
  self.searchEventCityStr = '';   //Selected EventCity Optiontext

  const resultListDomID = 'eventFilterResultList';

  //JQuery OnChange Event for select Eventtype
  $('#filterEventType').on('change', function() {
    filterEvents();
  });

  //JQuery OnChange Event for select Eventcity
  $("#filterEventCity").on('change', function(){
    filterEvents();
  });

  // Public methods
  EventFilterManager.prototype.search = function () {
    filterEvents();
  };

  /**
   * Init function
   */
  EventFilterManager.prototype.init = function() {

    self.dataObjList = [];
    self.objLocationList = [];
    self.objEventTypeList = [];
    var eventType = '';
    var state = '';
    var city = '';

    $('.ln-event-list__item').each(function(){

      var objDomData = [];
      eventType = $(this).data('event-type');
      state = $(this).data('location-state');
      city = $(this).data('location-city');

      //Fill DomData
      objDomData["eventTypeStr"] = eventType;
      objDomData["eventStateStr"] = state;
      objDomData["eventCityStr"] = city;
      objDomData["objRef"] = $(this);
      self.dataObjList.push(objDomData);

      //Fill EventTypeList
      if(parseInt(self.objEventTypeList.indexOf(eventType)) <= -1){
        self.objEventTypeList.push(eventType);
      }

      //Fill LocationList
      if(self.objLocationList[state]) {
        if(self.objLocationList[state].indexOf(city) <= -1 && city.length > 0){
          self.objLocationList[state].push(city);
        }
        self.objLocationList[state].sort();
      }
      else{
        if(state.length > 0){
          self.objLocationList[state] = [];
          if(city.length > 0) {
            self.objLocationList[state].push(city);
          }
        }
      }
    });

    selectBoxInit();
  };

  function selectBoxInit(){

    setEventTypeSelectOptions();
    setLocationSelectOptions();

  }

  //Append Options for Eventtype Selectbox
  function setEventTypeSelectOptions() {
    var $select = $('#filterEventType');
    for(key in self.objEventTypeList){

      $('<option />').html(self.objEventTypeList[key]).appendTo($select);
    }
  }

  //Append Options for Eventcity Selectbox
  function setLocationSelectOptions() {
    var $select = $('#filterEventCity');
    const ordered = {};

    //Sort List
    Object.keys(self.objLocationList).sort().forEach(function(key) {
      ordered[key] = self.objLocationList[key];
    });

    for(key in ordered){
      var group = $('<optgroup label="'+key+'" />');
      $.each(self.objLocationList[key], function(){
        $('<option />').html(this).appendTo(group);
      });
      group.appendTo($select);
    }
  }

  //Main Filter
  function filterEvents(){
    var dataSearchResult = [];

    $('#' + resultListDomID).empty(); //Clear searchlist
    showNoResult(false);
    $('.ln-event-list__item').hide();

    self.searchEventTypeStr = $("#filterEventType option:selected").val();
    self.searchEventCityStr = $("#filterEventCity option:selected").val();

    //None Selected
    if(self.searchEventTypeStr.length <= 0 && self.searchEventCityStr.length <= 0){
      //console.log('no');
      $('.ln-event-list__item').show();
      $('#' + resultListDomID).hide();
      return;
    }

    //None Selected EventCity
    if(self.searchEventTypeStr.length > 0 && self.searchEventCityStr.length <= 0){
      //console.log('type');
      filterEventType();
      return;
    }

    //None Selected EventType
    if(self.searchEventTypeStr.length == 0 && self.searchEventCityStr.length > 0){
      //console.log('city');
      filterEventCity();
      return;
    }

    //Filter Selected EventType && EventCity
    $.each(self.dataObjList, function(index,data)
    {
      //console.log('all');
      if (data.eventTypeStr === self.searchEventTypeStr && data.eventCityStr === self.searchEventCityStr) {
        dataSearchResult = data.objRef.clone();
        dataSearchResult.show();
        dataSearchResult.appendTo('#' + resultListDomID);
      }
    });

    $('#' + resultListDomID).show();
    if(parseInt(dataSearchResult.length) <= 0 ){
      showNoResult(true);
    };

  }

  function filterEventCity(){
    //cache update status
    var dataSearchResult = [];

    $.each(self.dataObjList, function(index,data)
    {
      if (data.eventCityStr === self.searchEventCityStr) {
        dataSearchResult = data.objRef.clone();
        dataSearchResult.show();
        dataSearchResult.appendTo('#' + resultListDomID);
      }
    });

    $('#' + resultListDomID).show();
  }

  function filterEventType(){
    //cache update status
    var dataSearchResult = [];

    $.each(self.dataObjList, function(index,data)
    {
      if (data.eventTypeStr === self.searchEventTypeStr) {
        dataSearchResult = data.objRef.clone();
        dataSearchResult.show();
        dataSearchResult.appendTo('#' + resultListDomID);
      }
    });

    $('#' + resultListDomID).show();
  }

  function showNoResult(status) {

    //console.log('a');

    if(status){
      //console.log('b');
      $('#eventFilterNoResult').show();
    }
    else{
      //console.log('c');
      $('#eventFilterNoResult').hide();
    }

  }
});
