/**
 * Created by AMA on 09.05.2017.
 */

var ConsultantSearchManager = (function(){

  var self = this;
  var maxViews = 10;          //max Consultants on Tab
  self.dataObjList = null;         //Consultants Block DOm Cache
  self.searchText = '';

  /**
   *
   * @constructor
   */
  function ConsultantSearchManager(){}

  /**
   * Show message box when search has no results
   */
  function toggleNoFoundMessage(countResults){
    if(countResults > 0){
      $('#noResultsMessage').hide();
    }
    else{
      $('#noResultsMessage').show();
    }
  }

  function toggleChevronButton(countResults){
    if(countResults > maxViews){
      $('#btnChevronSearch').show();
    }
    else{
      $('#btnChevronSearch').hide();
    }
  }

  /**
   * Validate Searchtext
   * @returns {boolean}
   */
  function validateInputSearch(searchText){
    if(searchText === ''){

      toggleNoFoundMessage(0);
      toggleChevronButton(0);
      deactivateActiveTabs();
      activateSearchTab();

      return false;
    }
    return true;
  }

  /**
   * Deactivate active tabs
   */
  function deactivateActiveTabs() {
    $('#consulting-office-nav').find('li.active').removeClass('active');
    $('#tabSearchResult').addClass('active');
  }

  /**
   * Activate Search Tab
   */
  function activateSearchTab(){
    $('.tab-pane').removeClass('in active');
    $('#contentTabSearchResult').addClass('in active');
  }

  /**
   * Init user click events
   */
  function eventsInit(){

    $("#inputConsultingSearch" ).on('keyup', function(){
      searchConsultants();
    });

    $("#btnConsultingSearch").on('click', function(){
      searchConsultants();
    });

    $("#btnChevronSearch").on('click', function(){
      $("#searchResultList").find(".consultants").slideDown('slow');
      $(this).hide();
    });

    $('.btnChevronOffice').on('click', function(){
      var tabId = $(this).data('tab-id').trim();
      $("#" + tabId + " .consultants").slideDown('slow');
      $(this).hide();
    });
  }

  function searchConsultants(){
    $('#searchResultList').empty(); //Clear searchlist
    var countResults = 0;
    var dataSearchResult = [];

    self.searchText = $( "#inputConsultingSearch").val(); //get text from input
    if(!validateInputSearch(self.searchText)) {
      return;
    }

    //cache update status
    $.each(self.dataObjList, function(index,data)
    {
      if (data.searchStr.indexOf(self.searchText.toLowerCase()) > -1) {
        countResults = countResults + 1;

        dataSearchResult = data.objRef.clone();

        if(countResults > maxViews){
          dataSearchResult.hide();
        }
        else {
          dataSearchResult.show();
        }

        dataSearchResult.appendTo('#searchResultList');
      }
    });

    toggleChevronButton(countResults);
    toggleNoFoundMessage(countResults);
    deactivateActiveTabs();
    activateSearchTab();
  }

  /**
   * Init function
   */
  ConsultantSearchManager.prototype.init = function() {

    self.dataObjList = [];

    $('.consultants').each(function(){
      var objDomData = [];
      objDomData["searchStr"] = $(this).data('person-name');
      objDomData["objRef"] = $(this);
      self.dataObjList.push(objDomData);
    });

    eventsInit();
  };

  // Public methods
  ConsultantSearchManager.prototype.search = function () {
    searchConsultants();
  };

  return ConsultantSearchManager;
}());