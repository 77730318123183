/**
 * Mobile Menu Component - register buttons events to show/hide navigation, mainMenu, megaMenu.
 * @author AndreasM
 * @since 02.04.2020
 * @param options
 */
function LnNavigation(options)
{
    let self = this;

    this.options = {
        btnNavigationOpenSelector: 'ln-header__btn-toggle',
        btnNavigationCloseSelector: 'ln-navigation__btn-close',
        btnMegaMenuOpenSelector: 'ln-main-menu__btn-megamenu',
        btnMegaMenuCloseSelector: 'ln-mega-menu__btn-close',
        navigationSelector: 'ln-navigation',
        navigationShowModifier: 'ln-navigation--show',
        megaMainMenuShowModifier: 'ln-main-menu__item--megamenu-show',
        mainMenuMouseInModifier: 'ln-main-menu--mouse-in',
        mainMenuSelector: 'ln-main-menu',
        mainMenuItemSelector: 'ln-main-menu__item',
        mainMenuItemActiveModifier: 'ln-main-menu__item--active',
        megaMenuItemSelector: 'ln-mega-menu__item',
        megaMenuItemActiveModifier: 'ln-mega-menu__item--active',
        bodyHasMobileMenuClass: 'has-mobile-menu',
        megaMenuMouseTimeout: 100
    };

    this.btnNavigationOpen = null;
    this.btnNavigationClose = null;
    this.btnMegaMenuOpenList = null;
    this.btnMegaMenuCloseList = null;
    this.mainMenu = null;
    this.megaMenuMouseTimerId = null;
    this.navigation = null;
    this.body = null;

    function init(options) {
        if (typeof options === 'object') {
            self.options = _.merge({}, self.options, options);
        }

        if (typeof self.options.btnNavigationOpenSelector === 'undefined' ||
            self.options.btnNavigationOpenSelector.length === 0) {
            console.error('No btnNavigationOpenSelector defined!');
            return;
        }
        self.btnNavigationOpen = document.getElementsByClassName(self.options.btnNavigationOpenSelector)[0];

        if (typeof self.options.btnNavigationCloseSelector === 'undefined' ||
            self.options.btnNavigationCloseSelector.length === 0) {
            console.error('No btnNavigationCloseSelector defined!');
            return;
        }
        self.btnNavigationClose = document.getElementsByClassName(self.options.btnNavigationCloseSelector)[0];

        if (typeof self.options.btnMegaMenuOpenSelector === 'undefined' ||
            self.options.btnMegaMenuOpenSelector.length === 0) {
            console.error('No btnMegaMenuOpenSelector defined!');
            return;
        }
        self.btnMegaMenuOpenList = document.getElementsByClassName(self.options.btnMegaMenuOpenSelector);

        if (typeof self.options.btnMegaMenuCloseSelector === 'undefined' ||
            self.options.btnMegaMenuCloseSelector.length === 0) {
            console.error('No btnMegaMenuCloseSelector defined!');
            return;
        }
        self.btnMegaMenuCloseList = document.getElementsByClassName(self.options.btnMegaMenuCloseSelector);

        if (typeof self.options.navigationSelector === 'undefined' ||
            self.options.navigationSelector.length === 0) {
            console.error('No navigationSelector defined!');
            return;
        }
        self.navigation = document.getElementsByClassName(self.options.navigationSelector)[0];

        self.mainMenu = document.getElementsByClassName(self.options.mainMenuSelector)[0];
        self.body = document.getElementsByTagName('body')[0];

        // exit if main menu is empty
        if(typeof self.mainMenu === 'undefined' ||self.mainMenu.length === 0) {
            return;
        }

        self.markLinksFromCurrentUrl();
        self.registerEventListener();
    }

    /**
     * Register button events
     */
    this.registerEventListener = function () {
        this.btnNavigationOpen.addEventListener('click', this.showNavigationMobile);
        this.btnNavigationClose.addEventListener('click', this.hideNavigationMobile);

        this.mainMenu.addEventListener('mouseover', this.hoverMainMenu);
        this.mainMenu.addEventListener('mouseleave', this.leaveMainMenu);

        for (let i = 0; i < this.btnMegaMenuOpenList.length; i += 1) {
            this.btnMegaMenuOpenList[i].addEventListener('click', this.clickMainMenuButton);
        }

        for (let i = 0; i < this.btnMegaMenuCloseList.length; i += 1) {
            this.btnMegaMenuCloseList[i].addEventListener('click', this.clickMegaMenuCloseButton);
        }
    };

    /**
     * Main menu mouse hover and add modifier
     */
    this.hoverMainMenu = function () {
        let item = this;

        self.showMegaMenuByTimer();
        item.removeEventListener('mouseover', self.hoverMainMenu);
    };

    /**
     * Main menu mouse hover and add modifier
     */
    this.leaveMainMenu = function () {
        let item = this;

        self.hideMegaMenuByTimer();
        item.addEventListener('mouseover', self.hoverMainMenu);
    };

    /**
     * @param event
     */
    this.clickMainMenuButton = function (event) {
        let button = this;

        if (event.cancelable === false) {
            return;
        }
        event.preventDefault();

        self.showMegaMenuMobile(button.parentElement);
    };

    /**
     * @param event
     */
    this.clickMegaMenuCloseButton = function (event) {
        if (event.cancelable === false) {
            return;
        }
        event.preventDefault();

        self.hideMegaMenuMobile();
    };

    /**
     * Add css navigation modifier and hide mega menu.
     * @param event
     */
    this.showNavigationMobile = function (event) {
        if (event.cancelable === false) {
            return;
        }
        event.preventDefault();

        if (self.navigation.classList.contains(self.options.navigationShowModifier) === true) {
            return;
        }
        self.navigation.classList.add(self.options.navigationShowModifier);
        self.body.classList.add(self.options.bodyHasMobileMenuClass);
    };

    /**
     * Remove css navigation modifier.
     * @param event
     */
    this.hideNavigationMobile = function (event) {
        if (event.cancelable === false) {
            return;
        }
        event.preventDefault();

        self.navigation.classList.remove(self.options.navigationShowModifier);
        self.body.classList.remove(self.options.bodyHasMobileMenuClass);
        self.hideMegaMenuMobile();
    };

    /**
     * Add mega menu modifier to show menu.
     * @param mainMenuItem
     */
    this.showMegaMenuMobile = function (mainMenuItem) {
        if (mainMenuItem.classList.contains(self.options.megaMainMenuShowModifier) === true) {
            return;
        }
        self.navigation.scrollTo({top: 0, left: 0});
        mainMenuItem.classList.add(self.options.megaMainMenuShowModifier);
    };

    /**
     * Remove all mega menu modifier and hide mega menu.
     */
    this.hideMegaMenuMobile = function () {
        let openedItems = document.getElementsByClassName(self.options.megaMainMenuShowModifier);
        for (let i = 0; i < openedItems.length; i += 1) {
            openedItems[i].classList.remove(self.options.megaMainMenuShowModifier);
        }
    };

    /**
     * Add modifier to main menu
     */
    this.showMegaMenuByTimer = function () {
        if (self.megaMenuMouseTimerId === null) {
            self.megaMenuMouseTimerId = setTimeout(function () {
                self.mainMenu.classList.add(self.options.mainMenuMouseInModifier);
                self.megaMenuMouseTimerId = null;
            }, self.options.megaMenuMouseTimeout);
        }
    };

    /**
     * Remove modifier from main menu
     */
    this.hideMegaMenuByTimer = function () {
        if (self.megaMenuMouseTimerId) {
            clearTimeout(self.megaMenuMouseTimerId);
            self.megaMenuMouseTimerId = null;
        }
        self.mainMenu.classList.remove(self.options.mainMenuMouseInModifier);
    };

    /**
     * Add active modifier to main-menu item if its link url is the same as the web url
     */
    this.markLinksFromCurrentUrl = function () {
        let menuItemList = document.getElementsByClassName(self.options.mainMenuItemSelector);

        Array.prototype.forEach.call(menuItemList, function (rootItem) {
            var megaMenuItems = rootItem.getElementsByClassName(self.options.megaMenuItemSelector);
            var megaMenuItemMarked = self.markMegaMenuItems(megaMenuItems);

            if (megaMenuItemMarked !== false) {
                rootItem.classList.add(self.options.mainMenuItemActiveModifier);
                return;
            }

            var rootLinks = rootItem.getElementsByTagName('a');
            if (rootLinks.length === 0) {
                return;
            }

            var rootItemUrl = rootLinks[0].getAttribute('href');
            if (window.location.pathname === rootItemUrl) {
                rootItem.classList.add(self.options.mainMenuItemActiveModifier);
            }
        });
    };

    /**
     * Add active modifier to mega-menu item if its link url is the same as the web url
     * @param megaMenuItems
     * @return {boolean}
     */
    this.markMegaMenuItems = function (megaMenuItems) {
        let marked = false;

        for (var i = 0; i < megaMenuItems.length; i += 1) {
            var megaMenuItem = megaMenuItems[i];

            if (typeof megaMenuItem === 'undefined') {
                continue;
            }

            var megaMenuUrl = megaMenuItem.getElementsByTagName('a')[0].getAttribute('href');
            if (window.location.pathname === megaMenuUrl) {
                megaMenuItems[i].classList.add(self.options.megaMenuItemActiveModifier);
                marked = true;
            }
        }

        return marked;
    };

    init(options);
}
