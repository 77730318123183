/**
 * @param start
 * @param end
 * @param duration
 * @param processed
 * @constructor
 */
function LnCounterElement(start, end, duration, processed) {
    this.processed = processed;
    this.start = start;
    this.end = end;
    this.duration = duration;
}

/**
 * WEB-418 Counter-Element "LucaNet in Zahlen"
 * @author AndreasM
 * @since 19.08.2020
 * @param options {selector, start, duration, framerate}
 * @constructor
 */
function LnAnimateCounter(options) {
    var self = this;

    this.elements = new Map();
    this.options = {
        selector: '.ln-js__animate-counter',
        start: 0,
        duration: 2000, // In milliseconds.
        framerate: 50 // In milliseconds (divide by 1000 to get seconds).
    };

    /**
     * @param options {Object}
     */
    function init(options) {
        if (typeof options === 'object') {
            self.options = _.merge({}, self.options, options);
        }

        self.elements = self._createElementMapFromNodeList(document.querySelectorAll(self.options.selector));
        if (self.elements === null || self.elements.size === 0) {
            return;
        }

        self.animate();
        $(window).scroll(function () {
            self.animate();
        });
    }

    this.animate = function () {
        this.elements.forEach(function (lnCounter, element) {
            if (lnCounter instanceof LnCounterElement && lnCounter.processed === false && self._isInViewport(element)) {
                self._animateElement(element, lnCounter);
                lnCounter.processed = true;
            }
        });
    };

    /**
     * @param elements {NodeList}
     * @return {Map}|null
     * @private
     */
    this._createElementMapFromNodeList = function (elements) {
        if (typeof elements === 'undefined' || elements.length === 0) {
            return null;
        }

        var elementMap = new Map();

        Array.prototype.forEach.call(elements, function (elem) {
            elementMap.set(elem, self._createCounterElement(elem));
        });

        return elementMap;
    };

    /**
     * @param element {Object}
     * @return {LnCounterElement}|null
     * @private
     */
    this._createCounterElement = function (element) {
        var end = element.innerText;
        if (isNaN(end)) {
            return null;
        }

        return new LnCounterElement(self.options.start, parseFloat(end), self.options.duration, false);
    };

    /**
     * @param element {Object}
     * @return {boolean}
     * @private
     * @see https://gomakethings.com/how-to-test-if-an-element-is-in-the-viewport-with-vanilla-javascript/
     */
    this._isInViewport = function (element) {
        var bounding = element.getBoundingClientRect();
        return (
            bounding.top >= 0 &&
            bounding.left >= 0 &&
            bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    };

    /**
     * @param element {object}
     * @param lnCounter {LnCounterElement}
     * @private
     */
    this._animateElement = function (element, lnCounter) {
        if (!(lnCounter instanceof LnCounterElement)) {
            return;
        }

        var timer;
        var current = lnCounter.start;
        var toAdd = ((lnCounter.end - lnCounter.start) * this.options.framerate) / lnCounter.duration;

        timer = setInterval(function () {
            current += toAdd;
            if (current >= lnCounter.end) {
                current = lnCounter.end;
                clearInterval(timer);
            }

            element.innerHTML = Math.floor(current).toLocaleString();
        }, this.options.framerate);
    };

    // initialize
    init(options);
}
