/**
 * lnPanelResizer
 * @author BjoernSc
 * @since 29.02.202017:52
 * @see WEB-370
 */
var lnPanelResizer = function () {
    var panelBlockClass = '.ln-panel:not(.ln-panel--columns)',
        panelElementClass = '.ln-panel__item',
        panelBlockDataAttribute = 'data-panel-id';

    /**
     * Creates simple identifier.
     * @return {string}
     */
    function createID() {
        return Math.random().toString(36).substr(2);
    }

    /**
     * Builds css selector for LnResizer.
     * @param id
     * @return {string}
     */
    function buildLnResizerSelector(id) {
        return '[' + panelBlockDataAttribute + '="' + id + '"]' + ' ' + panelElementClass;
    }

    if (typeof LnResizer === 'undefined') {
        console.log('lnPanelResizer could not found LnResizer!');
        return;
    }

    var list = document.querySelectorAll(panelBlockClass);
    Array.prototype.forEach.call(list, function (panel) {
        var id = createID();
        panel.setAttribute(panelBlockDataAttribute, id);
        new LnResizer(buildLnResizerSelector(id), true);
    });
};
