/**
 * Lnet-Slider
 * @author AndreasM
 * @since 22.03.2019 14:28
 * OWL-SLIDER: https://owlcarousel2.github.io/OwlCarousel2/docs/api-classes.html
 * ln:feature/1686 - Web-1004 Neues Header/Slider Layout
 */
function LnSlider(selector, options)
{
    /** unfortunately we have to use jQuery **/
    var self = this;

    /**
     * @type Object
     */
    this.options = {};

    /**
     * @type Object
     */
    this.defaultSelector = '[data-slider=heroBanner]';

    /**
     * @type Object
     */
    this.defaultOptions = {
        loop: true,
        nav: true,
        dots: true,
        navContainerClass: 'lnet-slider__nav',
        navClass: ['lnet-slider__prev', 'lnet-slider__next'],
        dotsClass: 'lnet-slider__indicator-wrapper',
        dotClass: 'lnet-slider__indicator',
        navText: [
            '<span class="fa fa-chevron-left"></span>',
            '<span class="fa fa-chevron-right"></span>'
        ],
        items: 1,
        autoplay: true,
        autoplayTimeout: 6000,
        autoplayHoverPause: true,
        autoplaySpeed: 600
    };

    /**
     * Constructor method.
     * @param selector undefined|string
     * @param options undefined|object
     */
    function init(selector, options)
    {
        if (typeof options === 'object') {
            self.options = _.merge({}, self.defaultOptions, options);
        }

        if (typeof selector === 'string' && selector.length > 0) {
            $(selector).owlCarousel(self.options);
            return;
        }

        $(self.defaultSelector).owlCarousel(self.options);
    }

    init(selector, options);
}
