/**
 * TabCollapsiblePanelControl
 * @author BijanO
 * @since 02.04.2020 17:50
 *
 * Expands panelBody when corresponding panelHeader is clicked or when tab linked to corresponding panel is clicked.
 * Other panelBodies are collapsed. Sets panels and tabs active and inactive accordingly.
 *
 * Behaves like an accordion except currently active panel/tab and expanded panelBody are only deactivated/collapsed
 * on click for desktop viewport.
 *
 * data-rel attribute needs to be set to same identifier on each linked tab and panel pair
 *
 * WEB-381 re-design locations
 */
function TabCollapsiblePanelControl(options) {
    var self = this;

    // properties initialized directly from options
    this.tabListSelector = undefined;
    this.tabActiveClass = undefined;
    this.tabSelector = undefined;

    this.panelClass = undefined;
    this.panelSelector = undefined;
    this.panelActiveClass = undefined;

    this.panelHeaderSelector = undefined;

    this.panelBodySelector = undefined;

    this.breakpointDesktopPX = undefined;

    this.options = {
        tabListSelector: '.ln-tab-list',
        tabActiveClass: 'ln-tab-list__tab--active',
        tabSelector: '.ln-tab-list__tab',
        panelClass: 'ln-collapsible-panel',
        panelSelector: '.ln-collapsible-panel',
        panelActiveClass: 'ln-collapsible-panel--active',
        panelHeaderSelector: '.ln-collapsible-panel__header',
        panelBodySelector: '.ln-collapsible-panel__body',
        breakpointDesktopPX: 992    // $ln-breakpoint-lg
    };

    //other properties
    this.tabs = undefined;
    this.panels = undefined;
    this.panelHeaders = undefined;
    this.panelBodies = undefined;
    this.initialTab = undefined;

    /**
     * Constructor method.
     * @param options
     */
    function init(options) {
        // initialize properties from options
        if (typeof options === 'object') {
            self.options = _.merge({}, self.options, options);
        }
        self.tabListSelector = self.options.tabListSelector;
        self.tabActiveClass = self.options.tabActiveClass;
        self.tabSelector = self.options.tabSelector;
        self.panelClass = self.options.panelClass;
        self.panelSelector = self.options.panelSelector;
        self.panelActiveClass = self.options.panelActiveClass;
        self.panelHeaderSelector = self.options.panelHeaderSelector;
        self.panelBodySelector = self.options.panelBodySelector;
        self.breakpointDesktopPX = self.options.breakpointDesktopPX;

        // initialize other properties
        self.tabs = document.querySelectorAll(self.tabListSelector + ' ' + self.tabSelector);
        self.panels = document.querySelectorAll(self.panelSelector);
        self.panelHeaders = document.querySelectorAll(self.panelSelector + ' ' + self.panelHeaderSelector);
        self.panelBodies = document.querySelectorAll(self.panelSelector + ' ' + self.panelBodySelector);
        self.initialTab = self.getActiveTab();

        // initialize max-heights
        self.resetMaxHeights();

        registerEventHandlers();
    }

    /**
     * helper method for init for registering event handlers on initialization
     *
     * @return void
     */
    function registerEventHandlers() {
        // initialize resize
        window.addEventListener('resize', function () {
            // set initial tab/panel to active and expand panel body if nothing is active on resize for desktop
            if (self.isDesktop() && self.getActiveTab() === null) {
                self.performSwitchOnTab(self.initialTab);
            }

            // reset max-heights on resize
            self.resetMaxHeights();
        });

        // initialize ui controls
        Array.prototype.forEach.call(self.tabs, function (element) {
            element.addEventListener('click', performSwitchOnTabHandler);
        });
        Array.prototype.forEach.call(self.panelHeaders, function (element) {
            element.addEventListener('click', performSwitchOnPanelHeaderHandler);
        });
    }

    // define event handler functions for initialization
    /**
     * @return void
     */
    function performSwitchOnTabHandler() {
        self.performSwitchOnTab(this);
    }

    /**
     * @return void
     */
    function performSwitchOnPanelHeaderHandler() {
        self.performSwitchOnPanelHeader(this);
    }

    // define methods
    /**
     * @return tab|null
     */
    this.getActiveTab = function () {
        let activeTab = null;

        Array.prototype.forEach.call(this.tabs, function (element) {
            if (element.classList.contains(self.tabActiveClass)) {
                activeTab = element;
            }
        });

        return activeTab;
    };

    /**
     * @param element
     * @return void
     */
    this.setMaxHeightToScrollHeight = function (element) {
        element.style.maxHeight = element.scrollHeight + 'px';
    };

    /**
     * @param element
     * @return void
     */
    this.setMaxHeightToZero = function (element) {
        element.style.maxHeight = 0;
    };

    /**
     * Reset maxHeight for all panelBodies
     *
     * @return void
     */
    this.resetMaxHeights = function () {
        Array.prototype.forEach.call(this.panels, function (element) {
            let panelBody = element.querySelector(self.panelBodySelector);

            if (element.classList.contains(self.panelActiveClass)) {
                self.setMaxHeightToScrollHeight(panelBody);

                return;
            }

            self.setMaxHeightToZero(panelBody);
        });
    };

    /**
     * @param element
     * @param className
     * @return Element
     */
    this.findClosestParent = function (element, className) {
        if (!element || element.classList.contains(className)) {
            return element;
        }

        return this.findClosestParent(element.parentNode, className);
    };

    /**
     * Set panel and tab to inactive and collapse panelBody if previously active and viewport is not desktop and,
     * inactivateActive parameter is set correspondingly
     * otherwise set to active and expand, set other panels and tabs to inactive and collapse other panelBodies
     *
     * @param panel
     * @param panelBody
     * @param tab
     * @param inactivateActive
     * @return void
     */
    this.performSwitch = function (panel, panelBody, tab, inactivateActive) {
        // we have to use this workaround, because default value will not work in older browsers
        let forceClose = true;
        if (typeof inactivateActive === "boolean" && inactivateActive === false) {
            forceClose = false;
        }
        // set panel and tab to inactive and collapse panelBody if previously active and viewport is not desktop and
        // inactivateActive parameter is set correspondingly
        // otherwise set to active and expand
        if (tab.classList.contains(this.tabActiveClass) && this.isDesktop() === false && forceClose) {
            tab.classList.remove(this.tabActiveClass);
            panel.classList.remove(this.panelActiveClass);
            this.setMaxHeightToZero(panelBody);
        } else {
            tab.classList.add(this.tabActiveClass);
            panel.classList.add(this.panelActiveClass);
            this.setMaxHeightToScrollHeight(panelBody);
        }

        // set other panels and tabs to inactive and collapse other panelBodies
        Array.prototype.forEach.call(this.tabs, function (element) {
            if (element === tab) {
                return;
            }

            element.classList.remove(self.tabActiveClass);
        });

        Array.prototype.forEach.call(this.panels, function (element) {
            if (element === panel) {
                return;
            }

            element.classList.remove(self.panelActiveClass);
        });

        Array.prototype.forEach.call(this.panelBodies, function (element) {
            if (element === panelBody) {
                return;
            }

            self.setMaxHeightToZero(element);
        });
    };

    /**
     * @param tab
     * @return void
     */
    this.performSwitchOnTab = function (tab) {
        let panel = null;
        Array.prototype.forEach.call(this.panels, function (element) {
            if (element.dataset.rel === tab.dataset.rel) {
                panel = element;
            }
        });

        if (!panel) {
            return;
        }

        let panelBody = panel.querySelector(this.panelBodySelector);

        if (panelBody) {
            this.performSwitch(panel, panelBody, tab);
        }
    };

    /**
     * @return void
     */
    this.performSwitchOnPanelHeader = function (panelHeader) {
        let panel = this.findClosestParent(panelHeader, this.panelClass);

        if (!panel) {
            return;
        }

        let panelBody = panel.querySelector(this.panelBodySelector);

        if (!panelBody) {
            return;
        }

        let tab = null;
        Array.prototype.forEach.call(this.tabs, function (element) {
            if (element.dataset.rel === panel.dataset.rel) {
                tab = element;
            }
        });

        if (tab) {
            this.performSwitch(panel, panelBody, tab);
        }
    };

    /**
     * To be used to call performSwitch for panel, panelBody and tab group defined by passed data-rel.
     *
     * @param rel
     * @param inactivateActive
     * @return void
     */
    this.performSwitchByRel = function (rel, inactivateActive) {
        let panel = null;
        let panelBody = null;
        let tab = null;
        let forceClose = false;

        if (typeof inactivateActive === "boolean" && inactivateActive === true) {
            forceClose = true;
        }

        Array.prototype.forEach.call(this.panels, function (element) {
            if (element.dataset.rel === rel) {
                panel = element;
            }
        });

        if (!panel) {
            return;
        }

        panelBody = panel.querySelector(this.panelBodySelector);

        if (!panelBody) {
            return;
        }

        Array.prototype.forEach.call(this.tabs, function (element) {
            if (element.dataset.rel === rel) {
                tab = element;
            }
        });

        if (tab) {
            this.performSwitch(panel, panelBody, tab, forceClose);
        }
    };

    /**
     * @return boolean
     */
    this.isDesktop = function () {
        return window.innerWidth >= this.breakpointDesktopPX;
    };

    // initialize
    init(options);
}
