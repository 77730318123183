/**
 * @author AndreasM
 * @since 22.07.2020 16:36
 * WEB-444 "Back to top"-Button
 *
 * @param options
 * @constructor
 */
function LnBackToTop(options) {
    let self = this;

    this.button = null;
    this.cookieLayer = null;
    this.options = {
        buttonSelector: '.ln-btn-back-to-top',
        buttonShowClass: 'ln-btn-back-to-top--show',
        scrollHeight: 2000
    };

    /**
     * @param options
     */
    function init(options) {
        if (typeof options === 'object') {
            self.options = _.merge({}, self.options, options);
        }

        if (typeof self.options.buttonSelector === 'undefined' || self.options.buttonSelector.length === 0) {
            console.log('LnBackToTop: No buttonSelector defined!');
            return;
        }
        self.button = document.querySelector(self.options.buttonSelector);
        if(!self.button) {
            console.log('LnBackToTop: No button found!');
            return;
        }


        //if page load than check page offset
        if (window.pageYOffset >= self.options.scrollHeight) {
            self.button.classList.add(self.options.buttonShowClass);
        }

        self._registerEventHandlers();
    }

    /**
     * @private
     */
    this._registerEventHandlers = function () {
        document.addEventListener('scroll', self._toggleButton);

        self.button.addEventListener('click', self._scrollTop);
    };

    /**
     * @private
     */
    this._toggleButton = function () {
        let position = window.pageYOffset;
        if (position >= self.options.scrollHeight) {
            self.button.classList.add(self.options.buttonShowClass);
        } else {
            self.button.classList.remove(self.options.buttonShowClass);
        }
    };

    /**
     * @param event
     * @private
     */
    this._scrollTop = function (event) {
        event.preventDefault();

        var i = window.pageYOffset;
        var int = setInterval(function () {
            window.scroll(0, i);
            i -= 30;

            if (i <= 0) {
                clearInterval(int);
            }
        }, 2);
    };

    // initialize
    init(options);
}
