/**
 * LnFlyoutSidebar
 * @author BijanO
 * @since 11.09.2019 14:52
 *
 * WEB-223 customer sticky sidebar
 */
function LnFlyoutSidebar(options) {
    let self = this;

    // properties initialized directly by constructor
    this.$window = null;

    this.options = {
        flyoutSelector: '.ln-flyout-sidebar',
        flyoutOpenedClass: 'ln-flyout-sidebar--opened',
        openButtonSelector: '.ln-sticky-sidebar__btn-flyout',
        closeButtonSelector: '.ln-flyout-sidebar__close'
    };

    // properties initialized by constructor from options
    this.$flyout = null;
    this.flyoutOpenedClass = null;
    this.$openButton = null;
    this.$closeButton = null;

    /**
     * Constructor method.
     * @param options object
     */
    function init(options) {
        // initialize properties directly
        self.$window = $(window);

        // initialize properties from options
        if (typeof options === 'object') {
            self.options = _.merge({}, self.options, options);
        }

        self.$flyout = $(self.options.flyoutSelector);
        self.flyoutOpenedClass = self.options.flyoutOpenedClass;
        self.$openButton = $(self.options.openButtonSelector);
        self.$closeButton = $(self.options.closeButtonSelector);

        registerEventHandlers();
    }

    /**
     * helper method for init for registering event handlers on initialization
     *
     * @return void
     */
    function registerEventHandlers() {
        // register: open flyout on click of open button
        self.$openButton.on('click', function (event) {
            self.openFlyout();
        });

        // register: close flyout on click of close button
        self.$closeButton.on('click', function (event) {
            self.closeFlyout();
        });
    }


    //  methods

    /**
     * open flyout and register _closeOnOuterClick event handler
     *
     * @return void
     */
    this.openFlyout = function () {
        this.$flyout.addClass(this.flyoutOpenedClass);

        this.$window.on('click', this._closeOnOuterClick);
    };

    /**
     * close flyout and deregister _closeOnOuterClick event handler
     *
     * @return void
     */
    this.closeFlyout = function () {
        this.$flyout.removeClass(this.flyoutOpenedClass);

        this.$window.off('click', this._closeOnOuterClick);
    };


    // event handlers

    /**
     * Call closeFlyout if event target is not flyout or any of its children
     * and also not openButton or any of its children.
     *
     * @param event object
     * @return void
     */
    this._closeOnOuterClick = function (event) {
        if (
            self.$flyout.is(event.target) === false
            && self.$flyout.find('*').is(event.target) === false
            && self.$openButton.is(event.target) === false
            && self.$openButton.find('*').is(event.target) === false
        ) {
            self.closeFlyout();
        }
    };


    // initialize
    init(options);
}
