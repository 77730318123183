function LnInstagramFeed(selector, token, options) {
    var self = this;
    this.token = token;
    this.$widget = null;
    this.$list = null;
    this.mediaTypeImage = 'IMAGE';
    this.options = {
        mediaUrl: 'https://graph.instagram.com/me/media?fields=media_url,media_type,permalink',
        listSelector: '.ln-instagram-feed-slider__list',
        itemClass: 'ln-instagram-feed-slider__item',
        loaderClass: 'ln-instagram-feed-slider--loaded',
        imgClass: 'img-responsive',
        itemTarget: '_blank',
        limit: 50,
        owlSlider: null
    };

    /**
     * @param selector
     * @param token
     * @param options
     */
    function init(selector, token, options) {
        if (typeof options === 'object') {
            self.options = _.merge({}, self.options, options);
        }

        if (typeof selector === 'undefined') {
            console.log('No selector defined!');
            return;
        }
        self.$widget = $(selector);

        if (typeof token === 'undefined') {
            console.log('No token defined!');
            return;
        }
        self.token = token;

        self._createList();
    }

    /**
     * @private
     */
    this._createList = function () {
        if (typeof self.options.listSelector !== 'string' || self.options.listSelector.length === 0) {
            console.log('Can not initialize list!');
            return;
        }

        self.$list = self.$widget.find(self.options.listSelector);
        this._getMediaData();
    };

    /**
     * @private
     */
    this._getMediaData = function () {
        self.$list.addClass(self.options.loaderClass);

        $.ajax(
            {
                method: 'GET',
                url: self._getMediaUrl(),
                success: self._successCallback,
                error: self._errorCallback,
                complete: self._completeCallback
            }
        );
    };

    /**
     * @return {string}
     * @private
     */
    this._getMediaUrl = function () {
        var url = self.options.mediaUrl + '&access_token=' + self.token;
        if (typeof self.options.limit === 'number' && self.options.limit > 0) {
            url = url + '&limit=' + self.options.limit;
        }

        return url;
    };

    /**
     * @param response
     * @private
     */
    this._successCallback = function (response) {
        if (response.data === undefined) {
            console.log('No response data.');
            return;
        }

        var data = response.data;
        for (var i = 0; i < data.length; i++) {
            self._addItem(data[i]);
        }
    };

    /**
     * @param xhr
     * @private
     */
    this._errorCallback = function (xhr) {
        if (typeof (xhr.responseText) !== 'undefined') {
            var response = JSON.parse(xhr.responseText);
            console.log('Api call exception: '
                + response.error.message
                + ' (' + response.error.code + ')');
        }

        self.$list.removeClass(self.options.loaderClass);
        self.$widget.hide();
    };

    /**
     * @private
     */
    this._completeCallback = function () {
        self.$list.removeClass(self.options.loaderClass);
    };

    /**
     * @param data
     * @private
     */
    this._addItem = function (data) {
        if (self._validate(data) === false) {
            return;
        }

        var item = self._createImageItem(data.media_url, data.permalink);

        if (item === null) {
            return;
        }

        if (self.options.owlSlider !== null) {
            self.options.owlSlider.owlCarousel('add', item).owlCarousel('update');
        } else {
            self.$list.append(item);
        }
    };

    /**
     * @param data
     * @return {boolean}
     * @private
     */
    this._validate = function (data) {
        return !(typeof data.media_url === 'undefined' || typeof data.media_type === 'undefined' || data.media_type !== self.mediaTypeImage);
    };

    /**
     * @param imageUrl
     * @param linkUrl
     * @return {HTMLAnchorElement|HTMLDivElement|null}
     * @private
     */
    this._createImageItem = function (imageUrl, linkUrl) {
        if (typeof (imageUrl) !== 'string' || imageUrl.length === 0) {
            return null;
        }

        var imageItem = document.createElement('img');
        imageItem.classList.add(self.options.imgClass);
        imageItem.setAttribute('src', imageUrl);

        if (typeof (linkUrl) === 'string' && linkUrl.length > 0) {
            var linkItem = document.createElement('a');
            linkItem.classList.add(self.options.itemClass);
            linkItem.setAttribute('target', self.options.itemTarget);
            linkItem.setAttribute('href', linkUrl);
            linkItem.append(imageItem);
            return linkItem;
        }

        var item = document.createElement('div');
        item.classList.add(self.options.itemClass);
        item.append(imageItem);
        return item;
    };

    init(selector, token, options);
}
