/**
 * LnResizer
 * @author BjoernSc
 * @since 04.03.2019 14:28
 *
 * ln:feature/1543 - Web-1176 Blog-Implementierung
 */
function LnResizer(selector, onResize)
{
    /** unfortunately we have to use jQuery **/
    var self = this;

    this.$list = null;

    /**
     * Constructor method.
     * @param selector string
     * @param onResize undefined|boolean
     */
    function init(selector, onResize)
    {
        self.$list = $(selector);

        self.resize();

        if (typeof onResize === "boolean" && onResize === true) {
            self.registerEventListener();
        }
    }

    this.resize = function () {
        var maxHeight = 0;

        // clear style attr
        this.$list.each(function () {
            $(this).attr('style', '');
        });

        this.$list.each(function () {
            var $this = $(this),
                outerHeight = $this.outerHeight();

            if (outerHeight > maxHeight) {
                maxHeight = outerHeight;
            }
        });

        this.$list.each(function () {
            $(this).outerHeight(maxHeight);
        });
    };

    this.registerEventListener = function () {
        $(window).resize(function () {
            self.resize();
        });
    };

    init(selector, onResize);
}