/**
 * Datenschutz-Grundverordnung (DSGVO)
 * englisch General Data Protection Regulation (GDPR)
 *
 *
 * @author Robert Zink
 */

function GDPRManager(_trackingCookieName, _trackingCookieVersion, _cookieSettingsLabel) {
    this.cookieExpires = 365;
    this.trackingAccpeted = false;
    this.tempTrackingAccepted = false;
    this.trackingCookieName = _trackingCookieName;
    this.trackingCookieVersion = _trackingCookieVersion;
    this.cookieSettingsLabel = _cookieSettingsLabel;
    this.cookieExist = false;

    // DOM IDs and Classes
    this.dom = {
        id_layer_cookie_message: "#dsgvo-cookie-layer",
        id_layer_cookie_message_dnt: "#dsgvo-cookie-dnt-layer",
        id_list_for_cookie_settings: "#cookie-settings",
        class_links_to_modal_view: ".cookieModalLink",

        id_modal_view: "#cookieModal",
        id_modal_btn_tracking_accept: "#dsgvo-checkbox-true",
        id_modal_btn_tracking_deny: "#dsgvo-checkbox-false",
        id_modal_btn_save: "#cookieModalBtnSave",

        class_modal_btn_close: ".close-modal-btn",
        class_btn_accept_tracking: ".btn_accept_tracking",
        class_btn_decline_tracking: ".btn_decline_tracking"
    };
}

GDPRManager.prototype.init = function () {
    this.loadCookie();
    this.createFooterLink();
    this.initModalView();
    this.registerModalView();
    this.displayTrackingCookieMessage();
};

GDPRManager.prototype.loadCookie = function () {
    var cookie = Cookies.get(this.trackingCookieName);

    if (cookie !== undefined) {
        var obj = jQuery.parseJSON(cookie);
        if (obj.version === this.trackingCookieVersion) {
            this.cookieExist = true;
        }
        if (obj.trackingAccpeted === true && obj.version === this.trackingCookieVersion) {
            this.trackingAccpeted = true;
            this.tempTrackingAccepted = true;
        } else {
            this.trackingAccpeted = false;
            this.tempTrackingAccepted = false;
        }
    }
};

GDPRManager.prototype.createFooterLink = function () {
    var cookieListItem = $("<li><a href='#' data-toggle='modal' data-target='#cookieModal'>" + this.cookieSettingsLabel + "</a></li>");
    $(this.dom.id_list_for_cookie_settings).append(cookieListItem);
};

GDPRManager.prototype.registerModalView = function () {
    var self = this;
    $(this.dom.class_links_to_modal_view).on("click", function () {
        $(self.dom.id_modal_view).modal("show");
    });
};

/**
 * https://caniuse.com/#feat=do-not-track
 * https://developer.mozilla.org/en-US/docs/Web/API/Navigator/doNotTrack
 */
GDPRManager.prototype.isDoNotTrack = function () {
    var isDNT = false;

    /**
     * This is the Standard, used in
     * ThisChrome >=23
     * Firefox >= 9
     * Firefox Mobile >= 9
     * Opera >= 12
     * Safari >= 5.1
     * Internet Explorer 11
     * Edge
     */
    if (window.navigator.doNotTrack == 1) {
        isDNT = true;
    }
    /**
     * Firefox Gecko < 32
     */
    if (window.navigator.doNotTrack == "yes") {
        isDNT = true;
    }
    /**
     * Internet Explorer 10
     * Internet Explorer 11
     */
    if (window.navigator.msDoNotTrack == "1") {
        isDNT = true;
    }
    /**
     * Safari 7.1.3+
     */
    if (window.doNotTrack == "1") {
        isDNT = true;
    }
    return isDNT;
};


GDPRManager.prototype.displayTrackingCookieMessage = function () {
    var obj = this;
    if (this.cookieExist) {
        return;
    } else {
        if (this.isDoNotTrack()) {
            $(this.dom.id_layer_cookie_message_dnt).show();
        } else {
            $(this.dom.id_layer_cookie_message).show();
        }
    }

    $(this.dom.class_btn_accept_tracking).on("click", function () {
        obj.tempTrackingAccepted = true;
        obj.updateCookie();
    });

    $(this.dom.class_btn_decline_tracking).on("click", function () {
        obj.tempTrackingAccepted = false;
        obj.updateCookie();
    });
};

GDPRManager.prototype.updateModalView = function () {
    if (this.tempTrackingAccepted) {
        $(this.dom.id_modal_btn_tracking_accept).addClass('active');
        $(this.dom.id_modal_btn_tracking_deny).removeClass('active');
    } else {
        $(this.dom.id_modal_btn_tracking_deny).addClass('active');
        $(this.dom.id_modal_btn_tracking_accept).removeClass('active');
    }
};

GDPRManager.prototype.initModalView = function () {
    var obj = this;

    $(obj.dom.id_modal_btn_save).on("click", function () {
        obj.updateCookie();
    });

    $(obj.dom.id_modal_btn_tracking_accept).on("click", function () {
        obj.tempTrackingAccepted = true;
        obj.updateModalView();
    });

    $(obj.dom.id_modal_btn_tracking_deny).on("click", function () {
        obj.tempTrackingAccepted = false;
        obj.updateModalView();
    });

    $(obj.dom.class_modal_btn_close).on("click", function () {
        obj.tempTrackingAccepted = obj.trackingAccpeted;
        obj.updateModalView();
    });

    $(obj.dom.id_modal_view).on('hide.bs.modal', function (e) {
        obj.tempTrackingAccepted = obj.trackingAccpeted;
        obj.updateModalView();
    });

    this.updateModalView();
};

GDPRManager.prototype.updateCookie = function () {
    this.writeCookie();
    window.location.reload();
};

GDPRManager.prototype.writeCookie = function () {
    this.trackingAccpeted = this.tempTrackingAccepted;
    Cookies.set(this.trackingCookieName, {
        'trackingAccpeted': this.trackingAccpeted,
        'version': this.trackingCookieVersion
    }, {
        expires: this.cookieExpires,
        domain: window.document.domain
    });
};