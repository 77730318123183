/**
 * LnSwipeZone
 * @author BijanO
 * @since 11.09.2019 14:52
 *
 * WEB-223 customer sticky sidebar
 */
function LnSwipeZone(options) {
    let self = this;

    // properties initialized directly by constructor
    this.touchValues = null;

    this.options = {
        callback1: function () {},
        callback2: function () {},
        zone : {
            x0: 0,
            x1: 100,
            y0: 0,
            y1: 100
        },
        direction : 'h',
        minDistance : 80
    };

    // properties initialized by constructor from options
    this.callback1 = null;
    this.callback2 = null;
    this.zone = null;
    this.direction = null;
    this.minDistance = null;

    /**
     * Constructor method.
     * @param options
     */
    function init(options) {
        // initialize properties directly
        self.touchValues = {
            touchstartX: 0,
            touchendX: 0,
            touchstartY: 0,
            touchendY: 0
        };

        // initialize properties from options
        if (typeof options === 'object') {
            self.options = _.merge({}, self.options, options);
        }


        if (self.validateCallback(self.options.callback1) === false) {
            console.error('SwipeZone: callback1 parameter is invalid!');
        }
        if (self.validateCallback(self.options.callback2) === false) {
            console.error('SwipeZone: callback2 parameter is invalid!');
        }
        if (self.validateZone(self.options.zone) === false) {
            console.error('SwipeZone: zone parameter is invalid!');
        }
        if (self.validateDirection(self.options.direction) === false) {
            console.error('SwipeZone: direction parameter is invalid!');
        }
        if (self.validateMinDistance(self.options.minDistance) === false) {
            console.error('SwipeZone: minDistance parameter is invalid!');
        }

        self.callback1 = self.options.callback1;
        self.callback2 = self.options.callback2;
        self.zone = self.options.zone;
        self.direction = self.options.direction;
        self.minDistance = self.options.minDistance;

        registerEventHandlers();
    }

    /**
     * helper method for init for registering event handlers on initialization
     *
     * @return void
     */
    function registerEventHandlers() {
        // update touch values on touchstart
        window.addEventListener('touchstart', function (event) {
            self.touchValues.touchstartX = event.changedTouches[0].clientX;
            self.touchValues.touchstartY = event.changedTouches[0].clientY;
        }, false);

        // update touch values on touchend and process swipe
        window.addEventListener('touchend', function (event) {
            self.touchValues.touchendX = event.changedTouches[0].clientX;
            self.touchValues.touchendY = event.changedTouches[0].clientY;

            self.processSwipe();
        }, false);

        // prevent the browser doing history things
        window.addEventListener('touchmove', function (event) {
            event.preventDefault();
        }, true);
    }


    // validation methods

    /**
     * validate callback
     * callback needs to be a function
     *
     * @param callback
     * @return boolean
     */
    this.validateCallback = function (callback) {
        return typeof callback === 'function';
    };

    /**
     validate zone object properties (x0,x1,y0,y1)
     zone properties describe a rectangle and are percent values in regards to viewport dimensions
     x0 must be smaller than x1, y0 must be smaller than y1

     @param zone
     @return boolean
     */
    this.validateZone = function (zone) {
        if (typeof zone === 'undefined') {
            return false;
        }

        let valid = true;

        // check that each coordinate of zone is a valid number representing a percent value
        if (
            zone.x0 < 0
            || zone.x0 > 100
            || zone.x1 < 0
            || zone.x1 > 100
            || zone.y0 < 0
            || zone.y0 > 100
            || zone.y1 < 0
            || zone.y1 > 100
        ) {
            valid = false;
        }

        if (
            typeof zone.x0 !== 'number'
            || typeof zone.x1 !== 'number'
            || typeof zone.y0 !== 'number'
            || typeof zone.y1 !== 'number'
        ) {
            valid = false;
        }

        // check that x0 smaller is smaller than x1 and y0 is smaller than y1
        if (zone.x0 >= zone.x1 || zone.y0 >= zone.y1) {
            valid = false;
        }

        return valid;
    };

    /**
     * validate expected direction for swipe
     *
     * @param direction
     * @returns boolean
     */
    this.validateDirection = function (direction) {
        return direction === 'h' || direction === 'v';
    };

    /**
     * validate value for minimum distance swipe needs to travel in pixels
     *
     * @param minDistance
     * @returns boolean
     */
    this.validateMinDistance = function (minDistance) {
        return typeof minDistance === 'number' && minDistance > 0;
    };


    // general methods

    /**
     * execute corresponding callback according to swipe direction
     * if touch movement started in zone, minimal distance has been travelled and the direction set has been followed
     *
     * @return void
     */
    this.processSwipe = function () {
        let pixelZone = this.getPixelZone();

        // return if touch movement started outside of zone
        if (
            this.touchValues.touchstartX < pixelZone.x0
            || this.touchValues.touchstartX > pixelZone.x1
            || this.touchValues.touchstartY < pixelZone.y0
            || this.touchValues.touchstartY > pixelZone.y1) {
            return;
        }

        // calculate difference between touch start and end
        let diffX = this.touchValues.touchendX - this.touchValues.touchstartX;
        let diffY = this.touchValues.touchendY - this.touchValues.touchstartY;

        // process horizontal swipe
        if (this.direction === 'h' && Math.abs(diffX) >= this.minDistance) {
            if (diffX > 0) {
                this.callback1();
            } else if (diffX < 0) {
                this.callback2();
            }
        }

        // process vertical swipe
        if (this.direction === 'v' && Math.abs(diffY) >= this.minDistance) {
            if (diffY > 0) {
                this.callback1();
            } else if (diffY < 0) {
                this.callback2();
            }
        }
    };


    /**
     * calculate pixel values out of percentage value zone and return that new zone
     *
     * @returns object
     */
    this.getPixelZone = function () {
        return {
            x0: Math.floor(this.zone.x0 * window.innerWidth / 100),
            x1: Math.floor(this.zone.x1 * window.innerWidth / 100),
            y0: Math.floor(this.zone.y0 * window.innerHeight / 100),
            y1: Math.floor(this.zone.y1 * window.innerHeight / 100)
        };
    };


    // initialize
    init(options);
}
